@for (appsList of splitAppsList; track $index) {
<div class="lib-row">
  @for (app of appsList; track $index) {
  <lib-card-shell class="card">
    <div (click)="goToApp(app.url)">
      <div class="lib-horizontal-space-evenly">
        @if (app.image) {
        <img class="image" src="{{ app.image }}" />
        } @if (!app.image && app.icon) {
        <mat-icon class="image">{{ app.icon }}</mat-icon>
        }
      </div>
      <div class="text">{{ app.name }}</div>
    </div>
  </lib-card-shell>
  }
</div>
}
