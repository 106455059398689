import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { LibFrameworkContentComponent } from './components/lib-framework-content/lib-framework-content.component';
import { LibFrameworkFooterComponent } from './components/lib-framework-footer/lib-framework-footer.component';
import { LibFrameworkNotificationEvent } from './components/lib-framework-notification/models/lib-framework-notification.model';
import { LibFrameworkToolbarComponent } from './components/lib-framework-toolbar/lib-framework-toolbar.component';
import { LibFrameworkUserPanelTopButton } from './models/lib-framework.model';

@Component({
  standalone: true,
  selector: 'lib-framework',
  templateUrl: './lib-framework.component.html',
  styleUrls: ['./lib-framework.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LibFrameworkFooterComponent,
    LibFrameworkToolbarComponent,
    LibFrameworkContentComponent,
  ],
})
export class LibFrameworkComponent {
  @Output() libNotificationEvent =
    new EventEmitter<LibFrameworkNotificationEvent>();
  @Output() libUserPanelTopButtonEvent =
    new EventEmitter<LibFrameworkUserPanelTopButton>();
  public emitNotification(event: LibFrameworkNotificationEvent): void {
    this.libNotificationEvent.emit(event);
  }

  public emitTopButton(topButton: LibFrameworkUserPanelTopButton): void {
    this.libUserPanelTopButtonEvent.emit(topButton);
  }
}
