import { Injectable } from '@angular/core';

import {
  LibFrameworkMenu,
  LibFrameworkSubMenu,
} from '../models/lib-framework.model';

@Injectable({
  providedIn: 'root',
})
export class LibFrameworkService {
  public filterMenu(
    menu: LibFrameworkMenu[],
    userGroups: string[],
    currentPath: string
  ): LibFrameworkMenu[] {
    let filteredMenu: LibFrameworkMenu[];
    filteredMenu = [];
    menu?.forEach((record) => {
      filteredMenu = [
        ...filteredMenu,
        {
          ...record,
          subMenus: this.createSubMenu(
            record?.subMenus,
            userGroups,
            currentPath
          ),
        },
      ];
    });

    return filteredMenu
      .filter((menu) => (menu.subMenus?.length || 0) > 0)
      .map((menu) => {
        return {
          ...menu,
          toggle: menu.subMenus?.some((subMenu) => subMenu.active),
        };
      });
  }

  private createSubMenu(
    subMenus: LibFrameworkSubMenu[] = [],
    userGroups: string[],
    currentPath: string
  ): LibFrameworkSubMenu[] {
    return subMenus
      ?.filter((recordSubMenu) =>
        this.authenticate(recordSubMenu.groups, userGroups)
      )
      .map((recordSubMenu) => {
        return {
          ...recordSubMenu,
          active: recordSubMenu.path === currentPath,
        };
      });
  }

  private authenticate(groups: string[] = [], userGroups: string[]): boolean {
    return (
      !groups ||
      groups.length === 0 ||
      groups.some((group) => userGroups?.includes(group))
    );
  }
}
