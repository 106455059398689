import { Routes } from '@angular/router';
import { AUTH_ROUTES } from 'src/app/auth/auth-routes';
import { LibFrameworkMenu } from 'src/lib/lib-framework/models/lib-framework.model';
import { NO_ACCESS_ROUTES } from 'src/app/_routes/no-access/no-access-routes';
import { NoAccessComponent } from 'src/app/_routes/no-access/no-access.component';
import {
  DASHBOARD_MAIN_MENU,
  DASHBOARD_MAIN_ROUTES,
} from './dashboard/dashboard-routes';
import { SETTINGS_MENU, SETTINGS_ROUTES } from './settings/settings-routes';
import { CRYPTO_MENU, CRYPTO_ROUTES } from './crypto/crypto-routes';

const defaultRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NoAccessComponent,
  },
];

export const FRAMEWORK_MENUS: LibFrameworkMenu[] = [
  DASHBOARD_MAIN_MENU,
  ...CRYPTO_MENU,
  ...SETTINGS_MENU,
];

export const LAZY_ROUTES: Routes = [
  ...AUTH_ROUTES,
  ...DASHBOARD_MAIN_ROUTES,
  ...CRYPTO_ROUTES,
  ...SETTINGS_ROUTES,
  ...NO_ACCESS_ROUTES,
  ...defaultRoutes,
];
