import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  filter,
  forkJoin,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
} from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';

import { User } from 'src/app/_user/models/user.model';
import { UserService } from 'src/app/_user/user.service';
import { environment } from 'src/environments/environment';
import { StoresService } from 'src/app/_stores/stores.service';
import { ChoicesService } from 'src/app/_choices/choices.service';
import { ChoicesList } from 'src/app/_choices/_models/choices-enum.model';
import { LibFrameworkComponent } from 'src/lib/lib-framework/lib-framework.component';
import { LibFrameworkUserPanelTopButton } from 'src/lib/lib-framework/models/lib-framework.model';
import { LibFrameworkDataService } from 'src/lib/lib-framework/services/lib-framework-data.service';
import { LibFrameworkNotificationEvent } from 'src/lib/lib-framework/components/lib-framework-notification/models/lib-framework-notification.model';

import {
  FRAMEWORK_APPS,
  FRAMEWORK_INITIAL_USER,
  FRAMEWORK_OPTIONS,
  FRAMEWORK_USER_PANEL,
  TopButtonEnum,
} from './app-config.model';
import { FRAMEWORK_MENUS } from './app-routes';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [LibFrameworkComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private libFrameworkData = inject(LibFrameworkDataService);
  private userService = inject(UserService);
  private stores = inject(StoresService);
  private choices = inject(ChoicesService);
  private authService = inject(MsalService);
  private msalBroadcast = inject(MsalBroadcastService);

  private $user = this.userService.$user;
  private sub = new Subscription();

  private $loadConfig = toSignal(
    toObservable(this.$user).pipe(switchMap((user) => this.setUser$(user)))
  );

  ngOnInit(): void {
    this.setFrameworkData();
    this.setAuth();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public emitNotification(event: LibFrameworkNotificationEvent): void {}

  public emitTopButton(topButton: LibFrameworkUserPanelTopButton): void {
    if (topButton.name === TopButtonEnum.SIGNOUT) {
      this.userService.signOutButton();
    }
  }

  private setFrameworkData(): void {
    this.libFrameworkData.$menus.set(FRAMEWORK_MENUS);
    this.libFrameworkData.$user.set(FRAMEWORK_INITIAL_USER);
    this.libFrameworkData.$appList.set(FRAMEWORK_APPS);
    this.libFrameworkData.$userPanel.set(FRAMEWORK_USER_PANEL);
  }

  private setAuth(): void {
    this.sub.add(
      this.msalBroadcast.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          )
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
        })
    );
  }

  private setUser$(user: User): Observable<void> {
    if (user.id) {
      this.libFrameworkData.$user.set({
        initials: user.initials,
        groups: user.permissionGroups,
      });

      this.libFrameworkData.$options.set({
        ...FRAMEWORK_OPTIONS,
        devMessage: environment.production ? '' : 'TEST SERVER',
      });

      return this.preload$();
    } else {
      return of(undefined);
    }
  }

  private preload$(): Observable<void> {
    return forkJoin([
      this.stores.userTableFilter.load$(),
      this.choices.loadChoices$([ChoicesList.COIN_TYPE]),
    ]).pipe(map(() => undefined));
  }
}
