import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toggle',
  standalone: true,
})
export class LibPipeTogglePipe implements PipeTransform {
  transform(toggleCloseList: string[], groupName: string): boolean {
    return !toggleCloseList.includes(groupName);
  }
}
