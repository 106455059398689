<div class="lib-fill">
  <div
    class="notification-bar lib-horizontal-space-between lib-vertical-center"
  >
    <div><strong>Notifications</strong></div>
    <button
      mat-button
      (click)="markAllAsRead()"
      class="small-font"
      [disabled]="libFrameworkData.$notifications() | unreadNotifications"
    >
      <span>MARK ALL AS READ</span>
    </button>
  </div>
  @for (
    notification of libFrameworkData.$notifications();
    track notification.id
  ) {
    <div
      class="notification-message lib-column lib-horizontal-center lib-vertical-start"
    >
      <div class="text lib-row">
        <div class="icon-box"></div>
        <div
          class="lib-horizontal-start lib-vertical-center"
          [ngClass]="{
            unread: !notification?.read,
          }"
          (mouseenter)="enterNotification(notification.id)"
          (mouseleave)="exitNotification(notification.id, hoverTime)"
        >
          {{
            notification.text
              | notificationsShortenText: notification.id : hoverId
          }}
        </div>
      </div>
      <div
        class="meta lib-row lib-horizontal-space-between lib-vertical-center"
      >
        <div>
          <mat-icon class="meta-icon">comment</mat-icon>
          {{ notification.date | dateTimeDifference }}
        </div>
        <button
          mat-button
          (click)="dismiss(notification.id)"
          class="small-font"
        >
          <span>DISMISS</span>
        </button>
      </div>
    </div>
  }
</div>