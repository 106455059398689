export enum LibFrameworkNotificationEventType {
  MARK_ALL_AS_READ = 'mark all as read',
  MARK_AS_READ = 'mark as read',
  DISMISS = 'dismiss',
}

export interface LibFrameworkNotificationEvent {
  readonly type: LibFrameworkNotificationEventType;
  readonly id?: number;
}

export enum LibFrameworkNotificationType {
  ALERT = 'alert',
  PUBLISH = 'publish',
  COMMENT = 'comment',
}

export interface LibFrameworkNotification {
  id: number;
  read: boolean;
  text: string;
  date: Date;
  type: LibFrameworkNotificationType;
  path: string;
  link?: boolean;
  icon?: string;
}
