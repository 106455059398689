import { Pipe, PipeTransform } from '@angular/core';
import { LibFrameworkNotification } from '../components/lib-framework-notification/models/lib-framework-notification.model';
@Pipe({
  name: 'showNavBadge',
  standalone: true,
})
export class LibFrameworkShowNavBadgePipe implements PipeTransform {
  transform(libFrameworkNotifications: LibFrameworkNotification[]): number {
    return (
      libFrameworkNotifications?.filter((notification) => !notification.read)
        .length || 0
    );
  }
}
