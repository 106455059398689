import { Routes } from '@angular/router';

import {
  LibFrameworkMenuType,
  LibFrameworkSubMenu,
} from 'src/lib/lib-framework/models/lib-framework.model';
import { RoutesGuard } from 'src/app/_routes/routes.guard';
import { UserGroupsList } from 'src/app/_user/models/user-enums.model';

export const SETTINGS_MENU_PATH = 'settings';
export const SETTINGS_MENU_MENU: LibFrameworkSubMenu = {
  name: 'Settings',
  path: SETTINGS_MENU_PATH,
  groups: [UserGroupsList.ADMIN],
  type: LibFrameworkMenuType.ROUTE,
  icon: 'settings',
};

export const SETTINGS_MENU_ROUTES: Routes = [
  {
    path: SETTINGS_MENU_MENU.path,
    loadComponent: () =>
      import('./settings-menu.component').then(
        (component) => component.SettingsMenuComponent
      ),
    canMatch: [RoutesGuard],
    data: SETTINGS_MENU_MENU,
  },
];
