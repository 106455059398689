import { Routes } from '@angular/router';

import { LibFrameworkMenu } from 'src/lib/lib-framework/models/lib-framework.model';

import {
  SETTINGS_MENU_MENU,
  SETTINGS_MENU_ROUTES,
} from './settings-menu/settings-menu-routes';
import { SETTINGS_COIN_TYPE_ROUTES } from './settings-coin-type/settings-coin-type-routes';

export const SETTINGS_MENU: LibFrameworkMenu[] = [
  {
    name: 'Settings',
    subMenus: [SETTINGS_MENU_MENU],
    singleMenu: true,
  },
];

export const SETTINGS_ROUTES: Routes = [
  ...SETTINGS_MENU_ROUTES,
  ...SETTINGS_COIN_TYPE_ROUTES,
];
