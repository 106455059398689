import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'pageName',
  standalone: true,
})
export class LibFrameworkPageNamePipe implements PipeTransform {
  transform(name?: string, title?: string): string {
    switch (true) {
      case !name:
        return title || '';
      case !title:
        return name || '';
      default:
        return `${title} - ${name}`;
    }
  }
}
