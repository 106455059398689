import { Routes } from '@angular/router';

import { LibFrameworkMenu } from 'src/lib/lib-framework/models/lib-framework.model';

import { COINS_MENU, COINS_ROUTES } from './coins/coins-routes';

export const CRYPTO_MENU: LibFrameworkMenu[] = [
  {
    name: 'Crypto',
    subMenus: [COINS_MENU],
    icon: 'view_list',
  },
];

export const CRYPTO_ROUTES: Routes = [...COINS_ROUTES];
