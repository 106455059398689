import { Pipe, PipeTransform } from '@angular/core';
import { LibStyleFrameworkClasses } from '../../../../lib-styles/models/lib-styles-framework-enum.model';
import { LibFrameworkSubMenu } from '../../../models/lib-framework.model';

@Pipe({
  name: 'isActiveMenu',
  standalone: true,
})
export class LibFrameworkIsActiveMenuPipe implements PipeTransform {
  transform(subMenu: LibFrameworkSubMenu): string {
    return subMenu?.active ? LibStyleFrameworkClasses.LIB_PRIMARY_COLOR : '';
  }
}
