import { Routes } from '@angular/router';

import {
  LibFrameworkMenuType,
  LibFrameworkSubMenu,
} from 'src/lib/lib-framework/models/lib-framework.model';
import { RoutesGuard } from 'src/app/_routes/routes.guard';
import { UserGroupsList } from 'src/app/_user/models/user-enums.model';

import { CRYPTO_PATH } from '../crypto.model';

export const COINS_PATH: string = `${CRYPTO_PATH}/coins`;
export const COINS_MENU: LibFrameworkSubMenu = {
  name: 'Coins',
  path: COINS_PATH,
  groups: [UserGroupsList.USER, UserGroupsList.ADMIN],
  type: LibFrameworkMenuType.ROUTE,
};

export const COINS_ROUTES: Routes = [
  {
    path: COINS_PATH,
    loadComponent: () =>
      import('./coins.component').then((component) => component.CoinsComponent),
    canMatch: [RoutesGuard],
    data: COINS_MENU,
  },
];
