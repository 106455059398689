import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map, mergeMap } from 'rxjs';

import {
  LibFrameworkMenuType,
  LibFrameworkSubMenu,
} from '../models/lib-framework.model';

@Injectable({
  providedIn: 'root',
})
export class LibFrameworkRouterService {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  public routes$(): Observable<LibFrameworkSubMenu> {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      );
  }

  public navigate(
    path?: string,
    libFrameworkMenuType?: LibFrameworkMenuType
  ): void {
    switch (libFrameworkMenuType) {
      case LibFrameworkMenuType.ROUTE:
        this.router.navigate([path]);
        return;
      case LibFrameworkMenuType.LINK:
        window.open(path, '_blank');
        return;
      default:
        return;
    }
  }
}
