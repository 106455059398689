import { Pipe, PipeTransform, inject } from '@angular/core';
import { LibFrameworkMenu } from '../models/lib-framework.model';
import { LibFrameworkService } from '../services/lib-framework.service';

@Pipe({
  name: 'filterMenu',
  standalone: true,
})
export class LibFrameworkFilterMenuPipe implements PipeTransform {
  private libFramework = inject(LibFrameworkService);
  transform(
    menu: LibFrameworkMenu[],
    userGroups: string[],
    currentPath: string
  ): LibFrameworkMenu[] {
    return this.libFramework.filterMenu(menu, userGroups, currentPath);
  }
}
