<mat-toolbar class="toolbar" color="primary">
  <div
    class="lib-icon-box-large lib-accent-hover framework-icon-right-margin"
    [ngClass]="{
      'lib-icon-box-open': libFrameworkData.$menuNavOpened()
    }"
    (click)="menuToggle()"
    >
    <div>
      <mat-icon class="lib-icon-button">menu</mat-icon>
    </div>
  </div>
  <h5>
    {{
    libFrameworkData.$routeName()
    | pageName : libFrameworkData.$options().title
    }}
  </h5>
  @if (libFrameworkData.$options().devMessage) {
    <h5>
      &nbsp;&nbsp;
      <span class="lib-warn-text">
        &nbsp;&nbsp;{{ libFrameworkData.$options().devMessage }}&nbsp;&nbsp;
      </span>
    </h5>
  }
  <span class="lib-flex-grow"></span>
  @if (libFrameworkData.$options().showNotificationIcon) {
    <div
      class="lib-icon-box-large lib-accent-hover framework-icon-right-margin"
    [ngClass]="{
      'lib-accent-color':
        libFrameworkData.$infoNavOpened() &&
        libFrameworkData.$navSideBar() ===
          libFrameworkInfoSideBarType.NOTIFICATION
    }"
      (click)="navToggle(libFrameworkInfoSideBarType.NOTIFICATION)"
      >
      <span>
        <mat-icon
          class="lib-icon-button"
          [matBadge]="libFrameworkData.$notifications() | showNavBadge"
          [matBadgeHidden]="!(libFrameworkData.$notifications() | showNavBadge)"
          matBadgeSize="small"
          matBadgePosition="after"
          matBadgeColor="warn"
          aria-hidden="false"
          >
          notifications
        </mat-icon>
      </span>
    </div>
  }

  @if (libFrameworkData.$options().showAppIcon) {
    <div
      class="lib-icon-box-large lib-accent-hover framework-icon-right-margin"
    [ngClass]="{
      'lib-accent-color':
        libFrameworkData.$infoNavOpened() &&
        libFrameworkData.$navSideBar() === libFrameworkInfoSideBarType.APPS
    }"
      (click)="navToggle(libFrameworkInfoSideBarType.APPS)"
      >
      <span>
        <mat-icon class="lib-icon-button">apps</mat-icon>
      </span>
    </div>
  }
  @if (libFrameworkData.$options().showUserIcon) {
    <div
      class="lib-icon-box-large lib-accent-hover framework-icon-right-margin"
    [ngClass]="{
      'lib-accent-color':
        libFrameworkData.$infoNavOpened() &&
        libFrameworkData.$navSideBar() === libFrameworkInfoSideBarType.USER
    }"
      (click)="navToggle(libFrameworkInfoSideBarType.USER)"
      >
      <div class="lib-icon-text-button">
        {{ libFrameworkData.$user().initials }}
      </div>
    </div>
  }
  <div class="logo lib-vertical-center">
    <img class="logo-size" src="./lib-logo.png" />
  </div>
</mat-toolbar>
