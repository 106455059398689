import { Routes } from '@angular/router';
import {
  LibFrameworkMenuType,
  LibFrameworkSubMenu,
} from 'src//lib/lib-framework/models/lib-framework.model';
import { UserGroupsList } from 'src/app/_user/models/user-enums.model';
import { NoAccessComponent } from './no-access.component';

export const NO_ACCESS_PATH = 'no-access';
export const NO_ACCESS_MENU: LibFrameworkSubMenu = {
  name: 'No Access',
  path: NO_ACCESS_PATH,
  groups: [UserGroupsList.USER],
  type: LibFrameworkMenuType.ROUTE,
};

export const NO_ACCESS_ROUTES: Routes = [
  {
    path: NO_ACCESS_PATH,
    children: [
      { path: '', component: NoAccessComponent },
      { path: ':url', component: NoAccessComponent },
    ],
    loadComponent: () =>
      import('./no-access.component').then(
        (component) => component.NoAccessComponent
      ),
    data: NO_ACCESS_MENU,
  },
];
