import {
  LibFrameworkOptions,
  LibFrameworkUser,
  LibFrameworkUserPanel,
  LibFrameworkUserPanelTopButton,
} from 'src/lib/lib-framework/models/lib-framework.model';
import { LibFrameworkAppsList } from 'src/lib/lib-framework/components/lib-framework-apps/models/lib-framework-apps.model';

export const FRAMEWORK_OPTIONS: LibFrameworkOptions = {
  title: 'Dashboard',
  menuIcons: false,
  showNotificationIcon: true,
  showUserIcon: true,
  showAppIcon: true,
};

export const FRAMEWORK_INITIAL_USER: LibFrameworkUser = {
  initials: '',
  groups: [],
};

export const FRAMEWORK_APPS: LibFrameworkAppsList[] = [
  {
    url: 'https://google.com/',
    icon: 'event',
    name: 'Holiday Calendar',
  },
  {
    url: 'https://google.com/',
    icon: 'info',
    name: 'Reference Materials',
  },
  {
    url: 'https://google.com/',
    icon: 'help_center',
    name: 'Support Ticket',
  },
];

export const topButtons: LibFrameworkUserPanelTopButton[] = [
  {
    icon: 'logout',
    name: 'Sign out',
  },
];

export const FRAMEWORK_USER_PANEL: LibFrameworkUserPanel = {
  topButtons: topButtons,
};

export enum TopButtonEnum {
  SIGNOUT = 'Sign out',
}
