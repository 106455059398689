import { Routes } from '@angular/router';
import {
  LibFrameworkMenu,
  LibFrameworkMenuType,
  LibFrameworkSubMenu,
} from 'src/lib/lib-framework/models/lib-framework.model';
import { RoutesGuard } from 'src/app/_routes/routes.guard';
import { UserGroupsList } from 'src/app/_user/models/user-enums.model';
import { DASHBOARD_MAIN_PATH } from './models/dashboard.model';

export const DASHBOARD_MAIN_SINGLE_MENU: LibFrameworkSubMenu = {
  name: 'Dashboard',
  path: DASHBOARD_MAIN_PATH,
  groups: [UserGroupsList.USER],
  type: LibFrameworkMenuType.ROUTE,
  icon: 'dashboard',
};

export const DASHBOARD_MAIN_ROUTES: Routes = [
  {
    path: DASHBOARD_MAIN_PATH,
    loadComponent: () =>
      import('../dashboard/dashboard.component').then(
        (component) => component.DashboardComponent
      ),
    canMatch: [RoutesGuard],
    data: DASHBOARD_MAIN_SINGLE_MENU,
  },
];

export const DASHBOARD_MAIN_MENU: LibFrameworkMenu = {
  name: 'Dashboard',
  subMenus: [DASHBOARD_MAIN_SINGLE_MENU],
  singleMenu: true,
};
