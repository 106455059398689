import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  inject,
} from '@angular/core';
import { LibFrameworkSubMenu } from '../../models/lib-framework.model';

import { LibFrameworkRouterService } from '../../services/lib-framework-router.service';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';
import { LibFrameworkFilterMenuPipe } from '../../pipes/lib-framework-filter-menu.pipe';

@Component({
  standalone: true,
  selector: 'lib-framework-menu-icon',
  templateUrl: './lib-framework-menu-icon.component.html',
  imports: [
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    LibFrameworkFilterMenuPipe
],
  styleUrls: ['./lib-framework-menu-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibFrameworkMenuIconComponent {
  public libFrameworkData = inject(LibFrameworkDataService);

  public libFrameworkRouter = inject(LibFrameworkRouterService);

  public navigate(subMenu: LibFrameworkSubMenu): void {
    this.libFrameworkData.$menuNavOpened.set(false);
    this.libFrameworkRouter.navigate(subMenu?.path, subMenu?.type);
    return;
  }
}
