import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LibPipeToggleCarrotIconPipe } from '../../../lib-pipes/lib-pipe-toggle-carrot-icon.pipe';
import { LibPipeTogglePipe } from '../../../lib-pipes/lib-pipe-toggle.pipe';
import {
  LibFrameworkMenu,
  LibFrameworkSubMenu,
} from '../../models/lib-framework.model';
import { LibFrameworkFilterMenuPipe } from '../../pipes/lib-framework-filter-menu.pipe';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';
import { LibFrameworkRouterService } from '../../services/lib-framework-router.service';
import {
  LIB_FRAMEWORK_MENU_SINGLE_STYLE,
  LIB_FRAMEWORK_MENU_SUB_MENU_STYLE,
} from './models/lib-framework-menu.model';
import { LibFrameworkIsActiveMenuPipe } from './pipes/lib-framework-is-active-menu.pipe';

@Component({
  standalone: true,
  selector: 'lib-framework-menu',
  templateUrl: './lib-framework-menu.component.html',
  styleUrls: ['./lib-framework-menu.component.scss'],
  imports: [
    NgTemplateOutlet,
    NgClass,
    NgStyle,
    MatIconModule,
    LibPipeTogglePipe,
    LibPipeToggleCarrotIconPipe,
    LibFrameworkIsActiveMenuPipe,
    LibFrameworkFilterMenuPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibFrameworkMenuComponent {
  public libFrameworkData = inject(LibFrameworkDataService);

  public libFrameworkMenuSingleStyle = LIB_FRAMEWORK_MENU_SINGLE_STYLE;
  public libFrameworkMenuSubMenuStyle = LIB_FRAMEWORK_MENU_SUB_MENU_STYLE;
  public filteredMenus: LibFrameworkMenu[] = [];
  public toggleCloseList: string[] = [];
  private libFrameworkRouter = inject(LibFrameworkRouterService);

  public changeToggle(groupName: string, toggleCloseList: string[]): void {
    if (toggleCloseList.includes(groupName)) {
      this.toggleCloseList = toggleCloseList.filter(
        (closed) => closed !== groupName
      );
    } else {
      this.toggleCloseList = [...toggleCloseList, groupName];
    }
    return;
  }

  public navigate(subMenu: LibFrameworkSubMenu): void {
    this.libFrameworkData.$menuNavOpened.set(false);
    this.libFrameworkRouter.navigate(subMenu?.path, subMenu?.type);
    return;
  }
}
