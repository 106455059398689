@if (routes$ | async; as routes) {
  <div class="lib-flex-grow lib-column framework-canvas">
    <mat-sidenav-container [hasBackdrop]="true" class="lib-fill">
      <mat-sidenav
        class="sidenav"
        #menuNav
        (openedChange)="setMenuOpen($event)"
        [opened]="libFrameworkData.$menuNavOpened()"
      >
        <lib-framework-menu></lib-framework-menu>
      </mat-sidenav>
      <mat-sidenav
        class="sidenav"
        #infoNav
        position="end"
        (openedChange)="setInfoOpen($event)"
        [opened]="libFrameworkData.$infoNavOpened()"
      >
        @if (
          libFrameworkData.$navSideBar() ===
          libFrameworkInfoSideBarType.NOTIFICATION
        ) {
          <lib-framework-notification
            (libNotificationEvent)="emitNotification($event)"
          ></lib-framework-notification>
        }
        @if (
          libFrameworkData.$navSideBar() === libFrameworkInfoSideBarType.USER
        ) {
          <lib-framework-user-menu
            (libUserPanelTopButtonEvent)="emitTopButton($event)"
          ></lib-framework-user-menu>
        }
        @if (
          libFrameworkData.$navSideBar() === libFrameworkInfoSideBarType.APPS
        ) {
          <lib-framework-apps></lib-framework-apps>
        }
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="lib-fill lib-row">
          @if (libFrameworkData.$options().menuIcons) {
            <div class="framework-icon-menu lib-flex-grow lib-primary-color">
              <lib-framework-menu-icon></lib-framework-menu-icon>
            </div>
          }
          <div
            class="lib-flex-grow framework-container"
            [ngClass]="{
              'framework-page-icon': libFrameworkData.$options().menuIcons,
              'framework-page': !libFrameworkData.$options().menuIcons,
            }"
          >
            @if (!libFrameworkData.$hideRoute()) {
              <router-outlet></router-outlet>
            }
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
}