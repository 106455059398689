import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LibFrameworkAppsList } from './models/lib-framework-apps.model';

import { LibCardShellComponent } from '../../../lib-card/lib-card-shell/lib-card-shell.component';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';

@Component({
  standalone: true,
  selector: 'lib-framework-apps',
  templateUrl: './lib-framework-apps.component.html',
  styleUrls: ['./lib-framework-apps.component.scss'],
  imports: [LibCardShellComponent, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibFrameworkAppsComponent implements OnInit {
  public libFrameworkData = inject(LibFrameworkDataService);
  public splitAppsList: LibFrameworkAppsList[][] = [];

  ngOnInit(): void {
    this.splitAppsList = this.splitUpApps(this.libFrameworkData.$appList());
  }

  public goToApp(url: string): void {
    window.open(url, '_blank');
  }

  private splitUpApps(
    appsList?: LibFrameworkAppsList[]
  ): LibFrameworkAppsList[][] {
    if (!appsList) {
      return [];
    }

    let splitApps: LibFrameworkAppsList[][] = [];
    let tempApps: LibFrameworkAppsList[] = [];
    const appsListLength = appsList.length;
    appsList.forEach((app, index) => {
      const position = index + 1;
      tempApps = [...tempApps, app];
      if (position % 3 === 0 || position === appsListLength) {
        splitApps = [...splitApps, tempApps];
        tempApps = [];
      }
    });

    return splitApps;
  }
}
