<div class="dark-theme-toggle lib-vertical-center lib-horizontal-end">
  @if ($userPanel().topButtons) {
    @for (topButton of $userPanel().topButtons; track $index) {
      <button
        mat-mini-fab
        (click)="emitTopButton(topButton)"
        class="lib-spacing-right"
      >
        <mat-icon>
          {{ topButton.icon }}
        </mat-icon>
      </button>
    }
  }
  <button mat-mini-fab (click)="$isDarkMode.set(!$isDarkMode())">
    <mat-icon>
      @if ($isDarkMode()) {
        dark_mode
      } @else {
        light_mode
      }
    </mat-icon>
  </button>
</div>