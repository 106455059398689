import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { LibStylesGreyDataService } from '../../../lib-styles/services/lib-styles-grey-data.service';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';
import {
  LIB_FRAMEWORK_FOOTER_PRIVACY_POLICY_LINK,
  LIB_FRAMEWORK_FOOTER_TERMS_OF_USE_LINK,
} from './models/lib-framework-footer-config.model';

@Component({
  standalone: true,
  selector: 'lib-framework-footer',
  templateUrl: './lib-framework-footer.component.html',
  styleUrls: ['./lib-framework-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class LibFrameworkFooterComponent implements OnInit {
  public libFrameworkData = inject(LibFrameworkDataService);
  public $greyColor = inject(LibStylesGreyDataService).$greyColor;
  public year?: number;
  public version?: string;
  public libFrameworkFooterTermsOfUseLink =
    LIB_FRAMEWORK_FOOTER_TERMS_OF_USE_LINK;
  public libFrameworkFooterPrivacyPolicyLink =
    LIB_FRAMEWORK_FOOTER_PRIVACY_POLICY_LINK;

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.version = this.libFrameworkData.$options().version
      ? `Version ${this.libFrameworkData.$options().version} `
      : '';
  }
}
