import { Pipe, PipeTransform } from '@angular/core';
import { LibFrameworkNotification } from '../models/lib-framework-notification.model';

@Pipe({
  name: 'unreadNotifications',
  standalone: true,
})
export class LibFrameworkUnreadNotificationsPipe implements PipeTransform {
  transform(libFrameworkNotifications: LibFrameworkNotification[]): boolean {
    return !libFrameworkNotifications?.some(
      (notification) => !notification.read
    );
  }
}
