export interface LibFrameworkMenuStyle {
  'font-weight'?: string;
  'padding-left'?: string;
}

export const LIB_FRAMEWORK_MENU_SINGLE_STYLE: LibFrameworkMenuStyle = {
  'font-weight': 'bold',
  'padding-left': '40px',
};

export const LIB_FRAMEWORK_MENU_SUB_MENU_STYLE: LibFrameworkMenuStyle = {
  'padding-left': '60px',
};
