<div class="lib-fill">
  <div class="menu-top-margin"></div>
  @for (
    menu of libFrameworkData.$menus()
      | filterMenu
        : libFrameworkData.$user().groups || []
        : libFrameworkData.$currentPath();
    track menu.name
  ) {
    <div class="menu-font">
      @if (!menu?.singleMenu) {
        <div class="lib-column">
          <div
            class="menu-spacing menu-link lib-accent-hover lib-vertical-center lib-horizontal-space-between"
            (click)="changeToggle(menu.name, toggleCloseList)"
          >
            <div class="lib-vertical-center lib-horizontal-space-between">
              <mat-icon>{{
                toggleCloseList | toggle: menu.name | toggleCarrotIcon
              }}</mat-icon>
              <span>
                @if (menu.icon && libFrameworkData.$options().menuIcons) {
                  <mat-icon class="lib-icon-button">
                    {{ menu.icon }}
                  </mat-icon>
                }
                <strong>{{ menu.name }}</strong>
              </span>
            </div>
          </div>
          @if (toggleCloseList | toggle: menu.name) {
            <div class="lib-column">
              @for (subMenu of menu.subMenus; track subMenu.name) {
                <ng-container
                  [ngTemplateOutlet]="menuButton"
                  [ngTemplateOutletContext]="{
                    $implicit: {
                      subMenu: subMenu,
                      style: libFrameworkMenuSubMenuStyle,
                      class: subMenu | isActiveMenu,
                    },
                  }"
                ></ng-container>
              }
            </div>
          }
        </div>
      } @else {
        <div class="lib-column menu-link lib-accent-hover">
          <ng-container
            [ngTemplateOutlet]="menuButton"
            [ngTemplateOutletContext]="{
              $implicit: {
                subMenu: menu.subMenus[0],
                style: libFrameworkMenuSingleStyle,
                class: menu.subMenus[0] | isActiveMenu,
              },
            }"
          >
          </ng-container>
        </div>
      }
    </div>
  }
</div>
<ng-template #menuButton let-data>
  <div
    class="menu-spacing menu-link lib-accent-hover lib-vertical-center lib-horizontal-start"
    [ngClass]="data.class"
    [ngStyle]="data.style"
    (click)="navigate(data.subMenu)"
  >
    @if (data.subMenu.icon && libFrameworkData.$options().menuIcons) {
      <mat-icon class="lib-icon-button">
        {{ data.subMenu.icon }}
      </mat-icon>
    }
    {{ data.subMenu.name }}
  </div>
</ng-template>