import { Routes } from '@angular/router';

export const AUTH_PATH: string = `auth`;

export const AUTH_ROUTES: Routes = [
  {
    path: AUTH_PATH,
    loadComponent: () =>
      import('./auth.component').then((component) => component.AuthComponent),
  },
];
