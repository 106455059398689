import {
  importProvidersFrom,
  Provider,
  EnvironmentProviders,
} from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';

export const LIB_PROVIDERS: (Provider | EnvironmentProviders)[] = [
  provideHttpClient(),
  provideAnimations(),
  importProvidersFrom([
    MatDialogModule,
    MatSnackBarModule,
    MatNativeDateModule,
  ]),
];
