import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';

import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LibDateService } from '../../../lib-date/lib-date.service';
import { LibPipeDateTimeDifferencePipe } from '../../../lib-pipes/lib-pipe-date-time-difference.pipe';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';
import {
  LibFrameworkNotification,
  LibFrameworkNotificationEvent,
  LibFrameworkNotificationEventType,
} from './models/lib-framework-notification.model';
import { LibFrameworkNotificationsShortenTextPipe } from './pipes/lib-framework-notifications-shorten-text.pipe';
import { LibFrameworkUnreadNotificationsPipe } from './pipes/lib-framework-unread-notifications.pipe';

@Component({
  standalone: true,
  selector: 'lib-framework-notification',
  templateUrl: './lib-framework-notification.component.html',
  styleUrls: ['./lib-framework-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    LibFrameworkUnreadNotificationsPipe,
    LibFrameworkNotificationsShortenTextPipe,
    LibPipeDateTimeDifferencePipe,
    MatButtonModule,
    MatIconModule,
  ],
})
export class LibFrameworkNotificationComponent {
  @Output() libNotificationEvent =
    new EventEmitter<LibFrameworkNotificationEvent>();

  public libFrameworkData = inject(LibFrameworkDataService);

  public hoverTime!: Date;
  public noUnreadNotifications = true;
  public hoverId = -1;

  private libDate = inject(LibDateService);

  public getMeta(date: Date): string {
    return this.libDate.textDifference(date);
  }

  public markAllAsRead(): void {
    this.libNotificationEvent.emit({
      type: LibFrameworkNotificationEventType.MARK_ALL_AS_READ,
    });
  }

  public dismiss(id: number): void {
    this.libNotificationEvent.emit({
      type: LibFrameworkNotificationEventType.DISMISS,
      id: id,
    });
  }

  public enterNotification(id: number): void {
    this.hoverId = id;
    this.hoverTime = new Date();
  }

  public exitNotification(id: number, hoverTime: Date): void {
    const currentTime = new Date();
    if (currentTime.valueOf() - hoverTime?.valueOf() > 1000) {
      this.libNotificationEvent.emit({
        type: LibFrameworkNotificationEventType.MARK_AS_READ,
        id: id,
      });
    }
    this.hoverId = -1;
  }

  public unreadNotifications(
    libFrameworkNotifications: LibFrameworkNotification[]
  ): boolean {
    return !libFrameworkNotifications?.some(
      (notification) => !notification.read
    );
  }
}
