import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationsShortenText',
  standalone: true,
})
export class LibFrameworkNotificationsShortenTextPipe implements PipeTransform {
  transform(text: string, id: number, hoverId: number): string {
    const textLength = text.length;

    if (textLength > 75 && id !== hoverId) {
      return `${text.substring(0, 75)}...`;
    } else {
      return text;
    }
  }
}
