<div class="icon-container">
  <div class="lib-fill">
    @for (
      menu of libFrameworkData.$menus()
        | filterMenu
          : libFrameworkData.$user().groups || []
          : libFrameworkData.$currentPath();
      track menu.name
    ) {
      <div class="menu-font lib-vertical-center lib-horizontal-center">
        @if (!menu?.singleMenu) {
          <div class="lib-accent-hover lib-column lib-icon-box-big">
            @if (menu.icon) {
              <mat-icon
                class="icon-spacing"
                [matTooltip]="menu.name || ''"
                matTooltipPosition="right"
                [matMenuTriggerFor]="navigationMenu"
              >
                {{ menu.icon }}
              </mat-icon>
            }
            <mat-menu #navigationMenu="matMenu" xPosition="after">
              @for (subMenu of menu.subMenus; track subMenu.name) {
                <button mat-menu-item (click)="navigate(subMenu)">
                  {{ subMenu.name }}
                </button>
              }
            </mat-menu>
          </div>
        } @else {
          <div class="lib-column lib-icon-box-big lib-accent-hover">
            @if (menu.subMenus[0].icon) {
              <mat-icon
                class="icon-spacing"
                [matTooltip]="menu.subMenus[0].name || ''"
                matTooltipPosition="right"
                (click)="navigate(menu.subMenus[0])"
              >
                {{ menu.subMenus[0].icon }}
              </mat-icon>
            }
          </div>
        }
      </div>
    }
  </div>
</div>