import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { LibFrameworkInfoSideBarType } from '../../models/lib-framework.model';
import { NgClass } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { LibFrameworkShowNavBadgePipe } from '../../pipes/lib-framework-show-nav-badge.pipe';
import { LibFrameworkPageNamePipe } from '../../pipes/lib-framework-page-name.pipe';
import { RouterOutlet } from '@angular/router';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';

@Component({
  standalone: true,
  selector: 'lib-framework-toolbar',
  templateUrl: './lib-framework-toolbar.component.html',
  styleUrls: ['./lib-framework-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    LibFrameworkShowNavBadgePipe,
    LibFrameworkPageNamePipe,
    RouterOutlet
],
})
export class LibFrameworkToolbarComponent {
  public libFrameworkData = inject(LibFrameworkDataService);

  public libFrameworkInfoSideBarType = LibFrameworkInfoSideBarType;

  public menuToggle(): void {
    this.libFrameworkData.$menuNavOpened.set(
      !this.libFrameworkData.$menuNavOpened()
    );
  }

  public navToggle(sideBar: LibFrameworkInfoSideBarType): void {
    this.libFrameworkData.$navSideBar.set(sideBar);
    this.libFrameworkData.$infoNavOpened.set(
      !this.libFrameworkData.$infoNavOpened()
    );
  }
}
