import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { UserService } from 'src/app/_user/user.service';
import { NavigateParams } from '../_user/models/user.model';

export const RoutesGuard: CanMatchFn = (route, state) => {
  switch (true) {
    case !inject(UserService).$user().id:
      const currentNavigation = inject(Router).getCurrentNavigation();
      const queryParams = currentNavigation?.initialUrl.queryParams;
      const path = state?.map((record) => record.path).join('/') || null;
      const navigateParams: NavigateParams = {
        path: path ?? '',
        queryParams: queryParams,
      };
      inject(UserService).$loadPage.update(
        (loadPage) => navigateParams || loadPage
      );
      inject(Router).navigate(['auth']);
      return false;
    case !!inject(UserService).$user().id && route.path === 'auth':
      inject(Router).navigate(['dashboard']);
      return false;
    case inject(UserService).authenticate(route?.data?.['groups']):
      return true;
    default:
      const url = encodeURIComponent(route.path || '');
      inject(Router).navigate([`no-access/${url}`]);
      return false;
  }
};
