import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';
import { LibFrameworkUserPanelTopButton } from '../../models/lib-framework.model';

@Component({
  standalone: true,
  selector: 'lib-framework-user-menu',
  templateUrl: './lib-framework-user-menu.component.html',
  styleUrls: ['./lib-framework-user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class LibFrameworkUserMenuComponent {
  @Output() libUserPanelTopButtonEvent =
    new EventEmitter<LibFrameworkUserPanelTopButton>();
  private dataService = inject(LibFrameworkDataService);
  public $isDarkMode = this.dataService.$isDarkMode;
  public $userPanel = this.dataService.$userPanel;

  public emitTopButton(topButton: LibFrameworkUserPanelTopButton): void {
    this.libUserPanelTopButtonEvent.emit(topButton);
  }
}