import { Routes } from '@angular/router';

import {
  LibFrameworkMenuType,
  LibFrameworkSubMenu,
} from 'src/lib/lib-framework/models/lib-framework.model';
import { RoutesGuard } from 'src/app/_routes/routes.guard';
import { UserGroupsList } from 'src/app/_user/models/user-enums.model';

import { SETTINGS_PATH } from '../settings-model';

export const SETTINGS_COIN_TYPE_PATH: string = `${SETTINGS_PATH}/coin-types`;
export const SETTINGS_COIN_TYPE_MENU: LibFrameworkSubMenu = {
  name: 'Coin Types',
  path: SETTINGS_COIN_TYPE_PATH,
  groups: [UserGroupsList.ADMIN],
  type: LibFrameworkMenuType.ROUTE,
};

export const SETTINGS_COIN_TYPE_ROUTES: Routes = [
  {
    path: SETTINGS_COIN_TYPE_PATH,
    loadComponent: () =>
      import('./settings-coin-type.component').then(
        (component) => component.SettingsCoinTypeComponent
      ),
    canMatch: [RoutesGuard],
    data: SETTINGS_COIN_TYPE_MENU,
  },
];
