import { CurrencyPipe, PercentPipe } from '@angular/common';
import { EnvironmentProviders, Provider } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { LIB_PROVIDERS } from 'src/lib/lib-providers/lib-providers.model';
import { MSAL_PROVIDERS } from 'src/app/app-msal-config';
import { LAZY_ROUTES } from './app-routes';

export const APP_PROVIDERS: (EnvironmentProviders | Provider)[] = [
    CurrencyPipe,
    PercentPipe,
    provideRouter(LAZY_ROUTES, withComponentInputBinding()),
    ...LIB_PROVIDERS,
    ...MSAL_PROVIDERS,
];
