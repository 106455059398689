import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toggleCarrotIcon',
  standalone: true,
})
export class LibPipeToggleCarrotIconPipe implements PipeTransform {
  transform(toggle: boolean): string {
    return toggle ? 'keyboard_arrow_down' : 'keyboard_arrow_right';
  }
}
