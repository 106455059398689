import { Pipe, PipeTransform, inject } from '@angular/core';
import { LibDateService } from '../lib-date/lib-date.service';

@Pipe({
  name: 'dateTimeDifference',
  standalone: true,
})
export class LibPipeDateTimeDifferencePipe implements PipeTransform {
  private libDate = inject(LibDateService);
  transform(start: Date, end: Date = new Date()): string {
    return this.libDate.textDifference(start, end);
  }
}
