import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Observable, tap } from 'rxjs';

import { AsyncPipe, NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {
  LibFrameworkInfoSideBarType,
  LibFrameworkSubMenu,
  LibFrameworkUserPanelTopButton,
} from '../../models/lib-framework.model';
import { LibFrameworkDataService } from '../../services/lib-framework-data.service';
import { LibFrameworkRouterService } from '../../services/lib-framework-router.service';
import { LibFrameworkAppsComponent } from '../lib-framework-apps/lib-framework-apps.component';
import { LibFrameworkMenuIconComponent } from '../lib-framework-menu-icon/lib-framework-menu-icon.component';
import { LibFrameworkMenuComponent } from '../lib-framework-menu/lib-framework-menu.component';
import { LibFrameworkNotificationComponent } from '../lib-framework-notification/lib-framework-notification.component';
import { LibFrameworkNotificationEvent } from '../lib-framework-notification/models/lib-framework-notification.model';
import { LibFrameworkUserMenuComponent } from '../lib-framework-user-menu/lib-framework-user-menu.component';

@Component({
  standalone: true,

  selector: 'lib-framework-content',
  templateUrl: './lib-framework-content.component.html',
  styleUrls: ['./lib-framework-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    AsyncPipe,
    MatSidenavModule,
    LibFrameworkMenuComponent,
    LibFrameworkNotificationComponent,
    LibFrameworkAppsComponent,
    LibFrameworkUserMenuComponent,
    LibFrameworkMenuIconComponent,
    RouterOutlet,
  ],
})
export class LibFrameworkContentComponent implements OnInit {
  @Output() libNotificationEvent =
    new EventEmitter<LibFrameworkNotificationEvent>();
  @Output() libUserPanelTopButtonEvent =
    new EventEmitter<LibFrameworkUserPanelTopButton>();
  public libFrameworkData = inject(LibFrameworkDataService);

  public libFrameworkInfoSideBarType = LibFrameworkInfoSideBarType;
  public routes$!: Observable<LibFrameworkSubMenu>;

  private libFrameworkRouter = inject(LibFrameworkRouterService);

  ngOnInit(): void {
    this.routes$ = this.libFrameworkRouter
      .routes$()
      .pipe(
        tap((routes) =>
          this.libFrameworkData.$currentPath.set(routes.path || ''),
        ),
      );
  }

  public setMenuOpen(status: boolean): void {
    this.libFrameworkData.$menuNavOpened.set(status);
  }

  public setInfoOpen(status: boolean): void {
    this.libFrameworkData.$infoNavOpened.set(status);
  }

  public emitNotification(event: LibFrameworkNotificationEvent): void {
    this.libNotificationEvent.emit(event);
  }
  public emitTopButton(topButton: LibFrameworkUserPanelTopButton): void {
    this.libUserPanelTopButtonEvent.emit(topButton);
  }
}